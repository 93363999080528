import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Components/Sidebar';
import Portfolio from './Components/Portfolio';
import Resume from './Components/Resume';
import About from './Components/About';
import Contact from './Components/Contact';

const App = () => {
	return (
		<div>
			<Routes>
				<Route path="/" element={<Sidebar />}>
					<Route index element={<About />} />
					<Route path="portfolio" element={<Portfolio />} />
					<Route path="resume" element={<Resume />} />
					<Route path="contact" element={<Contact />} />
					{/* <Route path="/portfolio" element={<Portfolio />} /> */}
				</Route>
			</Routes>
		</div>
	);
};

export default App;

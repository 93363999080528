import React from 'react';
import { Header, Nav, Button } from 'react-bootstrap';
// import Link from 'react-router-dom';
// import headshot from '../Images/portfolio/bryan_small.jpg';
import headshot from '../Images/portfolio/profile.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { NavLink, Outlet } from 'react-router-dom';

const Sidebar = () => {
	return (
		<div>
			<header className="header text-center">
				<div className="force-overflow">
					{/* <h1 className="blog-name pt-lg-4 mb-0">
						<a href="/">Bryan Herrera</a>
					</h1> */}

					<nav className="navbar navbar-expand-lg navbar-dark">
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navigation"
							aria-controls="navigation"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon" />
						</button>

						<div id="navigation" className="collapse navbar-collapse flex-column">
							<div className="profile-section pt-3 pt-lg-0">
								<img
									className="shadow-lg profile-image mb-3 rounded-circle mx-auto"
									src={headshot}
									alt="image"
								/>

								<div className="bio mb-3">
									Hi, my name is Bryan and I'm a mechanical engineer. Welcome to my personal website!
								</div>
								<ul className="social-list list-inline py-2 mx-auto">
									<li className="list-inline-item">
										<a href="https://www.linkedin.com/in/herrerabryan/">
											<i className="fab fa-linkedin-in fa-fw" />
										</a>
									</li>
									{/* <li className="list-inline-item">
										<a href="https://github.com/bcherrera14">
											<i className="fab fa-github-alt fa-fw" />
										</a>
									</li> */}
								</ul>
								<hr />
							</div>

							<ul className="navbar-nav flex-column text-left" id="sidebar">
								<NavLink className="nav-item" activeClassName="active" to="/">
									<a className="nav-link" href="/">
										<i className="fas fa-user fa-fw mr-2" />About Me<span className="sr-only">(current)</span>
									</a>
								</NavLink>

								{/* <NavLink className="nav-item" activeClassName="active" to="/portfolio">
									<a className="nav-link" href="/">
										<i className="fas fa-laptop-code fa-fw mr-2" />Portfolio
									</a>
								</NavLink> */}

								<NavLink className="nav-item" activeClassName="active" to="/resume">
									<a className="nav-link" href="resume">
										<i className="fas fa-file-alt fa-fw mr-2" />Resume
									</a>
								</NavLink>

								<NavLink className="nav-item" activeClassName="active" to="/contact">
									<a className="nav-link" href="contact">
										<i className="fas fa-envelope-open-text fa-fw mr-2" />Contact
									</a>
								</NavLink>
							</ul>

							<div className="my-2">
								<NavLink to="/contact">
									<a className="btn btn-primary" href="contact" target="_blank">
										<i className="fas fa-paper-plane mr-2" />Message
									</a>
								</NavLink>
							</div>

							<div className="dark-mode-toggle text-center w-100">
								<hr className="mb-4" />
								<h4 className="toggle-name mb-3 ">
									<i className="fas fa-adjust mr-1" />Dark Mode
								</h4>

								<input className="toggle" id="darkmode" type="checkbox" />
								<label className="toggle-btn mx-auto mb-0" for="darkmode" />
							</div>
						</div>
					</nav>
				</div>
			</header>
			<Outlet />
		</div>
	);
};

export default Sidebar;

import React from 'react';
import headshot from '../Images/portfolio/resume-profile.jpg';
import resume from '../PDF/BryanHerrera_Resume.pdf';

const Resume = () => {
	return (
		<div>
			<div class="main-wrapper">
				<div class="container px-3 px-lg-5">
					<article class="resume-wrapper mx-auto theme-bg-light p-5 mb-5 my-5 shadow-lg">
						<div class="resume-header">
							<div class="row align-items-center">
								<div class="resume-title col-12 col-md-6 col-lg-7 col-xl-8">
									<h2 class="resume-name mb-0 text-uppercase">Bryan Herrera</h2>
									<div class="resume-tagline mb-3 mb-md-0">Mechanical Engineer</div>
								</div>
								<div class="resume-contact col-12 col-md-6 col-lg-5 col-xl-4">
									<ul class="list-unstyled mb-0">
										{/* <li class="mb-2">
											<i class="fas fa-phone-square fa-fw fa-lg mr-2 " />
											<a class="resume-link" href="tel:#">
												0123 4567 890
											</a>
										</li> */}
										<li class="mb-2">
											<i class="fas fa-envelope-square fa-fw fa-lg mr-2" />
											<a class="resume-link" href="mailto:bryan.chris.herrera@gmail.com">
												bryan.chris.herrera@gmail.com
											</a>
										</li>
										<li class="mb-2">
											<i class="fas fa-globe fa-fw fa-lg mr-2" />
											<a class="resume-link" href="/">
												www.bryan-herrera.com
											</a>
										</li>
										<li class="mb-0">
											<i class="fas fa-map-marker-alt fa-fw fa-lg mr-2" />San Jose, CA
										</li>
									</ul>
								</div>
							</div>
						</div>
						<hr />
						<div class="resume-intro py-3">
							<div class="media flex-column flex-md-row align-items-center">
								{/* <img
									class="resume-profile-image mb-3 mb-md-0 mr-md-5 ml-md-0 rounded-circle mx-auto"
									src={headshot}
									alt="image"
								/> */}
								<div class="media-body text-left">
									<p class="mb-0">
										{/* Summarise your career here.{' '}
										<a
											class="theme-link"
											href="https://themes.3rdwavemedia.com/resources/sketch-template/resume-sketch-sketch-resume-template-for-software-developers/"
											target="_blank"
										>
											You can make a PDF version of your resume using our free Sketch template
											here
										</a>.  */}
										Mechanical engineer with 10 years of experience in designing and building
										electromechanical test fixtures and automated test systems to improve the design
										of new technologies. Searching for a position with a company where I can
										continue to strengthen my engineering skills and advance my career.{' '}
									</p>
								</div>
							</div>
						</div>
						<hr />
						<div class="resume-body">
							<div class="row">
								<div class="resume-main col-12 col-lg-8 col-xl-9 pr-0 pr-lg-5">
									<section class="work-section py-3">
										<h3 class="text-uppercase resume-section-heading mb-4">Work Experiences</h3>
										<div class="item mb-3">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
													Sr. Reliability Test Engineer
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
													ChargePoint | MAR 2023 - JAN 2024
												</div>
											</div>
											<div class="item-content">
												{/* <p>
													Role description goes here ipsum dolor sit amet, consectetuer
													adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
													sociis natoque penatibus et magnis dis parturient montes, nascetur
													ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
													pretium quis, sem. Donec pede justo, fringilla vel. Lorem ipsum
													dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
													eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
													parturient montes, nascetur ridiculus mus. Donec quam felis.
												</p> */}
												<ul class="resume-list">
													<li>
														Design and build automated test fixtures and other test aids for
														design verification and reliability tests.
													</li>
													<li>
														Develop creative test plans for environmental and mechanical
														stress tests for high power EV chargers.
													</li>
													<li>
														Work with cross-functional engineering teams to analyze test
														failures and suggest improvements.
													</li>
													<li>
														Train test technicians and create documentation for test
														methodology and test procedures.
													</li>
												</ul>
											</div>
										</div>
										<div class="item mb-3">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
													Sr. Mechanical Test Automation Engineer
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
													NETGEAR | 2016 - 2022
												</div>
											</div>
											<div class="item-content">
												{/* <p>
													Role description goes here ipsum dolor sit amet, consectetuer
													adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
													sociis natoque penatibus et magnis dis parturient montes, nascetur
													ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
													pretium quis sem. Donec pede justo, fringilla vel. Lorem ipsum
													dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
													eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
													parturient montes, nascetur ridiculus mus. Donec quam felis.
												</p> */}
												<ul class="resume-list">
													<li>
														Design and implement new electromechanical test processes that
														introduce automation and data collection.
													</li>
													<li>
														Work with R&D engineers to build wireless system test solutions
														using 5g, WiFi 6, and mmWave technologies.
													</li>
													<li>
														Used solidworks, 3D printing, and laser cutting to create
														industry-leading custom test fixtures.
													</li>
													<li>
														Developed automated test systems to improve the reliability of
														wireless communications with smartphones.
													</li>
													<li>
														Self-taught CNC machine operator in order to quickly turn around
														parts needed for custom in-house projects.
													</li>
													<li>
														Mentored interns to help them create and achieve short-term and
														long-term goals within the company.
													</li>
												</ul>
											</div>
										</div>
										<div class="item mb-3">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
													Mechanical Design Engineer
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
													Hive Design | 2014 - 2016
												</div>
											</div>
											<div class="item-content">
												{/* <p>
													Role description goes here ipsum dolor sit amet, consectetuer
													adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
													sociis natoque penatibus et magnis dis parturient montes, nascetur
													ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
													pretium quis, sem. Donec pede justo, fringilla vel. Lorem ipsum
													dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
													eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
													parturient montes, nascetur ridiculus mus. Donec quam felis.
												</p> */}
												<ul class="resume-list">
													<li>
														Designed, programmed, and prototyped a wireless fitness aid
														using sensors and microcontrollers.
													</li>
													<li>
														Designed, programmed, and built an automated assembly process
														using an arduino to decrease assembly time and increase assembly
														quality.
													</li>
													<li>
														Produced a successful Kickstarter project “Edwin: A Modern
														Minimal Wallet Inspired by the Binder Clip” that generated $37k
														in startup funds.
													</li>
												</ul>
											</div>
										</div>
										{/* <div class="item mb-3">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
													Lead Software Developer
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
													Apple | 2016 - 2019
												</div>
											</div>
											<div class="item-content">
												<p>
													Role description goes here ipsum dolor sit amet, consectetuer
													adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
													sociis natoque penatibus et magnis dis parturient montes, nascetur
													ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
													pretium quis, sem. Donec pede justo, fringilla vel.
												</p>
												<ul class="resume-list">
													<li>Lorem ipsum dolor sit amet, consectetuer.</li>
													<li>Aenean commodo ligula eget dolor.</li>
												</ul>
											</div>
										</div> */}
										{/* <div class="item mb-3">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
													Senior Software Developer
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
													Dropbox | 2014 - 2016
												</div>
											</div>
											<div class="item-content">
												<p>
													Role description goes here ipsum dolor sit amet, consectetuer
													adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
													sociis natoque penatibus et magnis dis parturient montes, nascetur
													ridiculus mus.
												</p>
											</div>
										</div> */}
										{/* <div class="item">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
													Senior Developer
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
													Uber | 2013 - 2014
												</div>
											</div>
											<div class="item-content">
												<p>
													Role description goes here ipsum dolor sit amet, consectetuer
													adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
													sociis natoque penatibus.{' '}
												</p>
											</div>
										</div> */}
										{/* <div class="item">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
													Backend Developer
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
													Amazon | 2014 - 2016
												</div>
											</div>
											<div class="item-content">
												<p>
													Role description goes here ipsum dolor sit amet, consectetuer
													adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
													sociis natoque penatibus et magnis dis parturient montes, nascetur
													ridiculus mus.
												</p>
											</div>
										</div> */}
										{/* <div class="item">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
													Frontend Developer
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
													Startup | 2013 - 2014
												</div>
											</div>
											<div class="item-content">
												<p>
													Role description goes here ipsum dolor sit amet, consectetuer
													adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
													sociis natoque penatibus.{' '}
												</p>
											</div>
										</div> */}
									</section>

									<section class="project-section py-3">
										<h3 class="text-uppercase resume-section-heading mb-4">Education</h3>
										<div class="item mb-3">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-7 mb-2 mb-md-0">
													Software Development Mastermind
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-5 text-muted text-left text-md-right">
													DEC 2020 - JUNE 2021
												</div>
											</div>
											{/* <div class="item-content">
												<ul class="resume-list">
													<li>Uses React and Bootstrap for front-end functionality.</li>
													<li>
														Conducts RESTful API request to IEX stock API for real time
														stock trading data.
													</li>
													<li>Uses ASP.NET to handle database data for use in the DOM.</li>
													<li>
														NHibernate handles PostgreSQL authentication, user, and stock
														holdings database queries.
													</li>
												</ul>
											</div> */}
										</div>
										<div class="item mb-3">
											<div class="item-heading row align-items-center mb-2">
												<h4 class="item-title col-12 col-md-6 col-lg-7 mb-2 mb-md-0">
													B.S. Mechanical Engineering, <p>Santa Clara University</p>
												</h4>
												<div class="item-meta col-12 col-md-6 col-lg-5 text-muted text-left text-md-right">
													SEPT 2010 - JUNE 2014
												</div>
											</div>
											{/* <div class="item-content">
												<ul class="resume-list">
													<li>
														The user can filter through twitter data by keyword or username.
													</li>
													<li>
														Uses custom API endpoints to fetch custom tweet data from the
														twitter API.
													</li>
													<li>Full-stack application using React and ASP.NET.</li>
												</ul>
											</div> */}
										</div>
									</section>
								</div>
								<aside class="resume-aside col-12 col-lg-4 col-xl-3 px-lg-4 pb-lg-4">
									<section class="skills-section py-3">
										<h3 class="text-uppercase resume-section-heading mb-4">Technical Skills</h3>
										<div class="item">
											{/* <h4 class="item-title">Technical</h4> */}
											<ul class="list-unstyled resume-skills-list">
												<li class="mb-2">SolidWorks</li>
												<li class="mb-2">Arduino</li>
												<li class="mb-2">3D Printing</li>
												<li class="mb-2">Laser Cutting</li>
												<li class="mb-2">Haas CNC</li>
												<li class="mb-2">Python</li>
												<li class="mb-2">Git & Github</li>
												<li class="mb-2">Javascript</li>
												<li class="mb-2">Jira</li>
												<li class="mb-2">Test Automation</li>
											</ul>
										</div>
										{/* <div class="item">
											<h4 class="item-title">Professional</h4>
											<ul class="list-unstyled resume-skills-list">
												<li class="mb-2">Effective communication</li>
												<li class="mb-2">Team player</li>
												<li class="mb-2">Strong problem solver</li>
												<li>Good time management</li>
											</ul>
										</div> */}
									</section>
									{/* <section class="education-section py-3">
										<h3 class="text-uppercase resume-section-heading mb-4">Education</h3>
										<ul class="list-unstyled resume-education-list">
											<li class="mb-3">
												<div class="resume-degree font-weight-bold">
													B.S. Mechanical Engineering
												</div>
												<div class="resume-degree-org text-muted">Santa Clara University</div>
												<div class="resume-degree-time text-muted">2010 - 2014</div>
											</li>
											<li>
												<div class="resume-degree font-weight-bold">
													Software Development Mastermind
												</div>
												<div class="resume-degree-time text-muted">2020 - Present</div>
											</li>
										</ul>
									</section> */}
									{/* <section class="education-section py-3">
										<h3 class="text-uppercase resume-section-heading mb-4">Awards</h3>
										<ul class="list-unstyled resume-awards-list">
											<li class="mb-3">
												<div class="font-weight-bold">Award Lorem Ipsum</div>
												<div class="text-muted">Microsoft lorem ipsum (2019)</div>
											</li>
											<li>
												<div class="font-weight-bold">Award Donec Sodales</div>
												<div class="text-muted">Oracle Aenean (2017)</div>
											</li>
										</ul>
									</section> */}
									{/* <section class="skills-section py-3">
										<h3 class="text-uppercase resume-section-heading mb-4">Languages</h3>
										<ul class="list-unstyled resume-lang-list">
											<li class="mb-2">
												English <span class="text-muted">(Native)</span>
											</li>
											<li>
												Spanish <span class="text-muted">(Professional)</span>
											</li>
										</ul>
									</section> */}
									<section class="skills-section py-3">
										<h3 class="text-uppercase resume-section-heading mb-4">Interests</h3>
										<ul class="list-unstyled resume-interests-list mb-0">
											<li class="mb-2">Running</li>
											<li class="mb-2">Hiking</li>
											<li class="mb-2">Golfing</li>
											<li>Traveling</li>
										</ul>
									</section>
								</aside>
							</div>
						</div>
						<hr />
						<div class="resume-footer text-center">
							<ul class="resume-social-list list-inline mx-auto mb-0 d-inline-block text-muted">
								{/* <li class="list-inline-item mb-lg-0 mr-3">
									<a class="resume-link" href="https://www.github.com/bcherrera14">
										<i class="fab fa-github-square fa-2x mr-2" data-fa-transform="down-4" />
										<span class="d-none d-lg-inline-block text-muted">github.com/bcherrera14</span>
									</a>
								</li> */}
								<li class="list-inline-item mb-lg-0 mr-3">
									<a class="resume-link" href="https://www.linkedin.com/in/herrerabryan/">
										<i class="fab fa-linkedin fa-2x mr-2" data-fa-transform="down-4" />
										<span class="d-none d-lg-inline-block text-muted">
											linkedin.com/in/herrerabryan/
										</span>
									</a>
								</li>
								{/* <li class="list-inline-item mb-lg-0 mr-lg-3">
									<a class="resume-link" href="#">
										<i class="fab fa-twitter-square fa-2x mr-2" data-fa-transform="down-4" />
										<span class="d-none d-lg-inline-block text-muted">@twittername</span>
									</a>
								</li> */}
							</ul>
						</div>
					</article>
				</div>
				<section class="cta-section theme-bg-light py-5">
					<div class="container text-center single-col-max-width">
						<a
							id="downloadResume"
							class="btn btn-primary"
							href={resume}
							target="_blank "
							download="BryanHerrera_Resume"
						>
							<i class="fas fa-file-pdf mr-2" />Download PDF
						</a>
					</div>
				</section>
				<footer className="footer text-center py-4">
					<small className="copyright">
						Bryan Herrera &copy; <span>Mechanical Engineer</span>
					</small>
				</footer>
			</div>
		</div>
	);
};

export default Resume;

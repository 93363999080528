import React from 'react';
import github from '../Images/icons/github-icon.jpg';
import arduino from '../Images/icons/arduino_icon.png';
import solidworks from '../Images/icons/solidworks_icon.png';
import python from '../Images/icons/python_icon.png';
import pi from '../Images/icons/rasppi_icon.png';
import printing from '../Images/icons/3dprint_icon.png';
import haas from '../Images/icons/haas_icon.png';
import database from '../Images/icons/db_icon.png';
import automation from '../Images/icons/automation.png';
import bootstraplogo from '../Images/icons/bootstrap-icon.jpg';
import dotnet from '../Images/icons/dotnet-icon.jpg';
import chargepoint from '../Images/portfolio/chargepoint.png';
import netgear from '../Images/portfolio/netgear.png';
import hive from '../Images/portfolio/hive.png';
import sifter from '../Images/portfolio/sifter.gif';
import stockfolio from '../Images/portfolio/stockfolio.gif';
import expensetracker from '../Images/portfolio/expensetracker.gif';
import productivity from '../Images/portfolio/productivity.gif';
import snake from '../Images/portfolio/snakegame.gif';
import resumephoto from '../Images/portfolio/resume-profile.jpg';
import { NavLink } from 'react-router-dom';

const About = () => {
	return (
		<div className="main-wrapper">
			<section className="about-me-section p-3 p-lg-5 theme-bg-light">
				<div className="container">
					<div className="profile-teaser media flex-column flex-lg-row">
						<div className="media-body">
							<h2 className="name font-weight-bold mb-1">Bryan Herrera</h2>
							<div className="tagline mb-3">Mechanical Engineer</div>
							<div className="bio mb-4">
								<p>
									I am a mechnaical engineer with 10 years of experience in reliability and test
									engineering. I enjoy using hardware and software along with my passion for problem
									solving to create innovative test solutions that help companies develop the best
									products.
								</p>
								<p>
									My focus is in automated test fixtures and data collection. I have the mechanical
									and software skillset to build functional test equipment paired with full-stack
									applications to monitor, control, and collect test data. If this is something you
									need help with feel free to <a href="contact">contact me</a>!
								</p>
							</div>
							<div className="mb-4">
								<NavLink to="/contact">
									<a className="btn btn-primary mr-2 mb-3" href="contact">
										<i className="fas fa-paper-plane mr-2" />
										<span className="">Message</span>
									</a>
								</NavLink>
								<NavLink to="/resume">
									<a className="btn btn-secondary mb-3" href="resume">
										<i className="fas fa-file-alt mr-2" />
										<span className="d-none d-md-inline">View</span> Resume
									</a>
								</NavLink>
							</div>
						</div>
						{/* <img className="profile-image mb-3 mb-lg-0 ml-lg-5 mr-md-0" src={resumephoto} alt="" /> */}
					</div>
				</div>
			</section>

			<section className="overview-section p-3 p-lg-5">
				<div className="container">
					<h2 className="section-title font-weight-bold mb-3">What I do</h2>
					<div className="section-intro mb-5">
						I am a hands-on engineer that enjoys the iterative process of bringing ideas to the real world.
						I enjoy using rapid prototying tools, mechatronics, and software to create one of a kind
						automated testing solutions. Below you will find some of my favortie technologies to work with.
					</div>
					<div className="row">
						<div className="item col-6 col-lg-3">
							<div className="item-inner d-flex flex-column align-items-center">
								<div className="item-icon pb-1">
									<img src={arduino} alt="arduino" className="" style={{ width: '48px' }} />
								</div>
								<h3 className="item-title">Arduino</h3>
							</div>
						</div>
						<div className="item col-6 col-lg-3">
							<div className="item-inner d-flex flex-column align-items-center">
								<div className="item-icon pb-1">
									<img src={solidworks} alt="solidworks" className="" style={{ width: '48px' }} />
								</div>
								<h3 className="item-title">SolidWorks</h3>
							</div>
						</div>
						<div className="item col-6 col-lg-3">
							<div className="item-inner d-flex flex-column align-items-center">
								<div className="item-icon pb-1">
									<img src={python} alt="python" className="" style={{ width: '48px' }} />
								</div>
								<h3 className="item-title">Python</h3>
							</div>
						</div>

						<div className="item col-6 col-lg-3">
							<div className="item-inner d-flex flex-column align-items-center">
								<div className="item-icon pb-1">
									<img src={pi} alt="raspberrypi" className="" style={{ width: '48px' }} />
								</div>
								<h3 className="item-title">Raspberry Pi</h3>
							</div>
						</div>
						<div className="item col-6 col-lg-3">
							<div className="item-inner d-flex flex-column align-items-center">
								<div className="item-icon pb-1">
									<img src={printing} alt="3d printing" className="" style={{ width: '48px' }} />
								</div>
								<h3 className="item-title">3D Printing</h3>
							</div>
						</div>
						<div className="item col-6 col-lg-3">
							<div className="item-inner d-flex flex-column align-items-center">
								<div className="item-icon pb-1">
									<img src={haas} alt="haas cnc" className="" style={{ width: '48px' }} />
								</div>
								<h3 className="item-title">Haas CNC</h3>
							</div>
						</div>
						<div className="item col-6 col-lg-3">
							<div className="item-inner d-flex flex-column align-items-center">
								<div className="item-icon pb-1">
									<img src={database} alt="database" className="" style={{ width: '48px' }} />
								</div>
								<h3 className="item-title">Database</h3>
							</div>
						</div>
						<div className="item col-6 col-lg-3">
							<div className="item-inner d-flex flex-column align-items-center">
								<div className="item-icon pb-1">
									<img src={automation} alt="automation" className="" style={{ width: '48px' }} />
								</div>
								<h3 className="item-title">Automation</h3>
							</div>
						</div>
					</div>
					{/* <div className="text-center py-3">
						<a href="services.html" className="btn btn-primary">
							<i className="fas fa-arrow-alt-circle-right mr-2" />Services &amp; Pricing
						</a>
					</div> */}
				</div>
			</section>

			{/* <section className="testimonials-section p-3 p-lg-5">
			<div className="container">
				<h2 className="section-title font-weight-bold mb-5">Testimonials</h2>
				
				<div className="testiomonial-carousel-container">
					<div className="testimonial-carousel  tiny-slider">
	                    <div className="item">
							<div className="item-inner">
								<div className="quote-holder">
									<blockquote className="quote-content">
										Simon is a brilliant software engineer! Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis.
									</blockquote>
									<i className="fas fa-quote-left"></i>
								
								<div className="source-holder">
									<div className="source-profile">
										<img src="assets/images/clients/profile-1.png" alt="image"/>
									</div>
									<div className="meta">
										<div className="name">Brandon James</div>
										<div className="info">Project Manager, Google</div>
									</div>
								</div>
							
						</div>
						
						<div className="item">
							<div className="item-inner">
								<div className="quote-holder">
									<blockquote className="quote-content">
										Simon is a brilliant software engineer! Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis.
									</blockquote>
									<i className="fas fa-quote-left"></i>
								</div>
								<div className="source-holder">
									<div className="source-profile">
										<img src="assets/images/clients/profile-2.png" alt="image"/>
									</div>
									<div className="meta">
										<div className="name">Kate Sanders</div>
										<div className="info">Project Manager, Uber</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="item">
							<div className="item-inner">
								<div className="quote-holder">
									<blockquote className="quote-content">
										Simon is a brilliant software engineer! Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis.
									</blockquote>
									<i className="fas fa-quote-left"></i>
								</div>
								<div className="source-holder">
									<div className="source-profile">
										<img src="assets/images/clients/profile-3.png" alt="image"/>
									</div>
									<div className="meta">
										<div className="name">James Lee</div>
										<div className="info">Product Manager, Amazon</div>
									</div>
								</div>
						    </div>
						</div>
						
						<div className="item">
							<div className="item-inner">
								<div className="quote-holder">
									<blockquote className="quote-content">
										Simon is a brilliant software engineer! Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis.
									</blockquote>
									<i className="fas fa-quote-left"></i>
								</div>
								<div className="source-holder">
									<div className="source-profile">
										<img src="assets/images/clients/profile-4.png" alt="image"/>
									</div>
									<div className="meta">
										<div className="name">James Lee</div>
										<div className="info">Product Manager, Amazon</div>
									</div>
								</div>
						    </div>
						</div>
						
						<div className="item">
							<div className="item-inner">
								<div className="quote-holder">
									<blockquote className="quote-content">
										Simon is a brilliant software engineer! Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis.
									</blockquote>
									<i className="fas fa-quote-left"></i>
								</div>
								<div className="source-holder">
									<div className="source-profile">
										<img src="assets/images/clients/profile-5.png" alt="image"/>
									</div>
									<div className="meta">
										<div className="name">Olivia White</div>
										<div className="info">Product Manager, Dropbox</div>
									</div>
								</div>
						    </div>
						</div>
						
						<div className="item">
							<div className="item-inner">
								<div className="quote-holder">
									<blockquote className="quote-content">
										Simon is a brilliant software engineer! Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis.
									</blockquote>
									<i className="fas fa-quote-left"></i>
								</div>
								<div className="source-holder">
									<div className="source-profile">
										<img src="assets/images/clients/profile-6.png" alt="image"/>
									</div>
									<div className="meta">
										<div className="name">Samuel Reyes</div>
										<div className="info">CTO, StartupHub</div>
									</div>
								</div>
						    </div>
						</div>
					</div>
				</div>
			</div>
		</section> */}

			<div className="container">
				<hr />
			</div>

			<section className="featured-section p-3 p-lg-5">
				<div className="container">
					<h2 className="section-title font-weight-bold mb-5">Experience</h2>
					<div className="row">
						<div className="col-md-6 mb-5">
							<div className="card project-card">
								<div className="row no-gutters">
									<div className="card-img-holder">
										<img src={chargepoint} className="card-img" alt="image" />
									</div>
									<div className="">
										<div className="card-body">
											<h5 className="card-title mb-0">ChargePoint</h5>
											<p className="card-text">
												<small className="text-muted">Sr. Reliability Test Engineer</small>
											</p>
											<p className="card-text">
												Designed and built automated test fixtures and other test aids for
												design verification and reliability tests. Worked with cross-functional
												engineering teams to develop test plans for environmental and mechanical
												stress tests for high power EV chargers.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 mb-5">
							<div className="card project-card">
								<div className="row no-gutters">
									<div className="card-img-holder">
										<img src={netgear} className="card-img" alt="image" />
									</div>
									<div className="">
										<div className="card-body">
											<h5 className="card-title mb-0">NETGEAR</h5>
											<p className="card-text">
												<small className="text-muted">
													Sr. Mechanical Test Automation Engineer
												</small>
											</p>
											<p className="card-text">
												Designed and fabricated electromechanical test systems for product
												development of the latest hardware in wireless technology.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 mb-5">
							<div className="card project-card">
								<div className="row no-gutters">
									<div className="card-img-holder">
										<img src={hive} className="card-img" alt="image" />
									</div>
									<div className="">
										<div className="card-body">
											<h5 className="card-title mb-0">Hive Design</h5>
											<p className="card-text">
												<small className="text-muted">Mechanical Design Engineer</small>
											</p>
											<p className="card-text">
												Worked with industrial designers and other mechanical engineers to
												create prototypes, custom manufacturing equipment, and in-house
												products. Check out our successful{' '}
												<a
													href="https://www.kickstarter.com/projects/897010326/edwin-a-modern-minimal-wallet-inspired-by-the-bind"
													target="_blank"
												>
													Kickstarter Campaign
												</a>{' '}
												where we successfully raised over $37k!
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <div className="container">
				<hr />
			</div> */}

			{/* <section className="latest-blog-section p-3 p-lg-5">
				<div className="container">
					<h2 className="section-title font-weight-bold mb-5">Latest Blog Posts</h2>
					<div className="row">
						<div className="col-md-4 mb-5">
							<div className="card blog-post-card">
								<img
									className="card-img-top"
									src="assets/images/blog/blog-post-thumb-card-1.jpg"
									alt="image"
								/>
								<div className="card-body">
									<h5 className="card-title">
										<a className="theme-link" href="blog-post.html">
											Top 3 JavaScript Frameworks
										</a>
									</h5>
									<p className="card-text">
										Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
										eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
										parturient...
									</p>
									<p className="mb-0">
										<a className="more-link" href="blog-post.html">
											Read more &rarr;
										</a>
									</p>
								</div>
								<div className="card-footer">
									<small className="text-muted">Published 2 days ago</small>
								</div>
							</div>
						</div>
						<div className="col-md-4 mb-5">
							<div className="card blog-post-card">
								<img
									className="card-img-top"
									src="assets/images/blog/blog-post-thumb-card-2.jpg"
									alt="image"
								/>
								<div className="card-body">
									<h5 className="card-title">
										<a className="theme-link" href="blog-post.html">
											About Remote Working
										</a>
									</h5>
									<p className="card-text">
										Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
										eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
										parturient...
									</p>
									<p className="mb-0">
										<a className="more-link" href="blog-post.html">
											Read more &rarr;
										</a>
									</p>
								</div>
								<div className="card-footer">
									<small className="text-muted">Published a week ago</small>
								</div>
							</div>
						</div>
						<div className="col-md-4 mb-5">
							<div className="card blog-post-card">
								<img
									className="card-img-top"
									src="assets/images/blog/blog-post-thumb-card-3.jpg"
									alt="image"
								/>
								<div className="card-body">
									<h5 className="card-title">
										<a className="theme-link" href="blog-post.html">
											A Guide to Becoming a Full-Stack Developer
										</a>
									</h5>
									<p className="card-text">
										Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
										eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
										parturient...
									</p>
									<p className="mb-0">
										<a className="more-link" href="blog-post.html">
											Read more &rarr;
										</a>
									</p>
								</div>
								<div className="card-footer">
									<small className="text-muted">Published 3 weeks ago</small>
								</div>
							</div>
						</div>
					</div>
					<div className="text-center py-3">
						<a href="blog-home.html" className="btn btn-primary">
							<i className="fas fa-arrow-alt-circle-right mr-2" />View Blog
						</a>
					</div>
				</div>
			</section> */}

			<footer className="footer text-center py-4">
				<small className="copyright">
					Bryan Herrera &copy; <span>Mechanical Engineer</span>
				</small>
			</footer>
		</div>
	);
};

export default About;

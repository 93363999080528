import React from 'react';
import sifter from '../Images/portfolio/sifter.gif';
import expensetracker from '../Images/portfolio/expensetracker.gif';
import starwars from '../Images/portfolio/starwars.gif';
import snake from '../Images/portfolio/snakegame.gif';
import productivity from '../Images/portfolio/productivity.gif';
import stockfolio from '../Images/portfolio/stockfolio.gif';
import { NavLink } from 'react-router-dom';

const Portfolio = () => {
	return (
		<div class="main-wrapper">
			<section class="cta-section theme-bg-light py-5">
				<div class="container text-center single-col-max-width">
					<h2 class="heading">Portfolio</h2>
					<div class="intro">
						<p>
							As a full-stack developer I enjoy using my passion to build things, attention to detail and
							design, and the opportunity for lifelong learning to create applications. Check out some of
							my latest projects!
						</p>
					</div>
					<NavLink to="/contact">
						<a className="btn btn-primary" href="contact" target="_blank">
							<i className="fas fa-paper-plane mr-2" />Message
						</a>
					</NavLink>
				</div>
			</section>
			<section class="projects-list px-3 py-5 p-md-5">
				<div class="container">
					{/* <div class="text-center">
						<ul id="filters" class="filters mb-5 mx-auto pl-0">
							<li class="type active mb-3 mb-lg-0" data-filter="*">
								All
							</li>
							<li class="type  mb-3 mb-lg-0" data-filter=".webapp">
								We App
							</li>
							<li class="type  mb-3 mb-lg-0" data-filter=".mobileapp">
								Mobile App
							</li>
							<li class="type  mb-3 mb-lg-0" data-filter=".frontend">
								Frontend
							</li>
							<li class="type  mb-3 mb-lg-0" data-filter=".backend">
								Backend
							</li>
						</ul>
					</div> */}

					<div class="project-cards row isotope">
						{/* //=========Stockfolio=========== */}
						<div class="isotope-item col-md-6 mb-5 mobileapp frontend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="card-img-holder">
										<img src={stockfolio} class="card-img" alt="image" />
									</div>
									<div class="">
										<div class="card-body">
											<h5 class="card-title">
												<a href="https://github.com/bcherrera14/stockfolio" class="theme-link">
													Stockfolio
												</a>
											</h5>
											<p class="card-text">
												This full-stack application is created using React for the frontend and
												PostgreSQL for the backend database. This application allows the user to
												create an account, buy stocks, sell stocks, and monitor stock
												performance.
											</p>
											<p class="card-text">
												<small class="text-muted">React | PostgreSQL | Azure</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="https://github.com/bcherrera14/stockfolio" />
									<div class="link-mask-text">
										<a class="btn btn-secondary" href="https://github.com/bcherrera14/stockfolio">
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div>
						{/* //=========Sifter=========== */}
						<div class="isotope-item col-md-6 mb-5 mobileapp frontend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="card-img-holder">
										<img src={sifter} class="card-img" alt="image" />
									</div>
									<div class="">
										<div class="card-body">
											<h5 class="card-title">
												<a
													href="https://github.com/bcherrera14/twitter-search"
													class="theme-link"
												>
													Sifter App
												</a>
											</h5>
											<p class="card-text">
												This full-stack application is created using React for the frontend and
												.NET web api for the backend. This application allows the user to search
												through tweets by keywords or username. This application utilizes the
												twitter api and configures the twitter data to display tweet-like cards.
											</p>
											<p class="card-text">
												<small class="text-muted">React | .NET | Azure</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="https://github.com/bcherrera14/twitter-search" />
									<div class="link-mask-text">
										<a
											class="btn btn-secondary"
											href="https://github.com/bcherrera14/twitter-search"
										>
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div>
						{/* //=========Productivity=========== */}
						<div class="isotope-item col-md-6 mb-5 mobileapp frontend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="card-img-holder">
										<img src={productivity} class="card-img" alt="image" />
									</div>
									<div class="">
										<div class="card-body">
											<h5 class="card-title">
												<a
													href="https://github.com/bcherrera14/productivity-app"
													class="theme-link"
												>
													Productivity Tracker
												</a>
											</h5>
											<p class="card-text">
												This application uses, react, functional components, and react hooks to
												create a frontend application that tracks a users productivity. The goal
												of this application was to get familiar with functional components and
												react hooks.
											</p>
											<p class="card-text">
												<small class="text-muted">React | React Hooks | Bootstrap</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="https://github.com/bcherrera14/productivity-app" />
									<div class="link-mask-text">
										<a
											class="btn btn-secondary"
											href="https://github.com/bcherrera14/productivity-app"
										>
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div>
						{/* //=========Expense=========== */}
						<div class="isotope-item col-md-6 mb-5 mobileapp">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="card-img-holder">
										<img src={expensetracker} class="card-img" alt="image" />
									</div>
									<div class="">
										<div class="card-body">
											<h5 class="card-title">
												<a
													href="https://github.com/bcherrera14/expensetracker-app"
													class="theme-link"
												>
													Expense Tracker App
												</a>
											</h5>
											<p class="card-text">
												This application is hosted on Heroku and uses the framework React along
												with JavaScript, HTML, and CSS. In this application a user can input and
												keep track of their expenses in a formatted expense table.
											</p>
											<p class="card-text">
												<small class="text-muted">React</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a
										class="link-mask-link"
										href="https://github.com/bcherrera14/expensetracker-app"
									/>
									<div class="link-mask-text">
										<a
											class="btn btn-secondary"
											href="https://github.com/bcherrera14/expensetracker-app"
										>
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div>

						{/* //=========Starwars=========== */}
						<div class="isotope-item col-md-6 mb-5 webapp frontend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="card-img-holder">
										<img src={starwars} class="card-img" alt="image" />
									</div>
									<div class="">
										<div class="card-body">
											<h5 class="card-title">
												<a
													href="https://github.com/bcherrera14/starwars-app"
													class="theme-link"
												>
													Star Wars Directory
												</a>
											</h5>
											<p class="card-text">
												This application is created using react along with the axios library for
												making request to an external API. The user can enter any star wars
												character name and see the corresponding data in the table below.
											</p>
											<p class="card-text">
												<small class="text-muted">React | RESTful API</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="https://github.com/bcherrera14/starwars-app" />
									<div class="link-mask-text">
										<a class="btn btn-secondary" href="https://github.com/bcherrera14/starwars-app">
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div>
						{/* //=========Snake=========== */}
						<div class="isotope-item col-md-6 mb-5 webapp backend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="card-img-holder">
										<img src={snake} class="card-img" alt="image" />
									</div>
									<div class="">
										<div class="card-body">
											<h5 class="card-title">
												<a href="https://github.com/bcherrera14/snake-game" class="theme-link">
													Snake App
												</a>
											</h5>
											<p class="card-text">
												I built the classic snake game in vanilla JavaScript, HTML and CSS. I
												designed a new UI to give it a modern look.
											</p>
											<p class="card-text">
												<small class="text-muted">Vanilla Javascript</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="https://github.com/bcherrera14/snake-game" />
									<div class="link-mask-text">
										<a class="btn btn-secondary" href="https://github.com/bcherrera14/snake-game">
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div>
						{/* <div class="isotope-item col-md-6 mb-5 mobileapp frontend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="col-lg-4 card-img-holder">
										<img src="assets/images/project/project-5.jpg" class="card-img" alt="image" />
									</div>
									<div class="col-lg-8">
										<div class="card-body">
											<h5 class="card-title">
												<a href="project.html" class="theme-link">
													Project Heading
												</a>
											</h5>
											<p class="card-text">
												Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cum sociis
												natoque penatibus et magnis dis parturient montes, nascetur ridiculus
												mus.{' '}
											</p>
											<p class="card-text">
												<small class="text-muted">Client: SalesForce</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="project.html" />
									<div class="link-mask-text">
										<a class="btn btn-secondary" href="project.html">
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div> */}
						{/* <div class="isotope-item col-md-6 mb-5 webapp backend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="col-lg-4 card-img-holder">
										<img src="assets/images/project/project-6.jpg" class="card-img" alt="image" />
									</div>
									<div class="col-lg-8">
										<div class="card-body">
											<h5 class="card-title">
												<a href="project.html" class="theme-link">
													Project Heading
												</a>
											</h5>
											<p class="card-text">
												Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cum sociis
												natoque penatibus et magnis dis parturient montes, nascetur ridiculus
												mus.{' '}
											</p>
											<p class="card-text">
												<small class="text-muted">Client: Uber</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="project.html" />
									<div class="link-mask-text">
										<a class="btn btn-secondary" href="project.html">
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div> */}
						{/* <div class="isotope-item col-md-6 mb-5 webapp frontend backend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="col-lg-4 card-img-holder">
										<img src="assets/images/project/project-7.jpg" class="card-img" alt="image" />
									</div>
									<div class="col-lg-8">
										<div class="card-body">
											<h5 class="card-title">
												<a href="project.html" class="theme-link">
													Project Heading
												</a>
											</h5>
											<p class="card-text">
												Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cum sociis
												natoque penatibus et magnis dis parturient montes, nascetur ridiculus
												mus.{' '}
											</p>
											<p class="card-text">
												<small class="text-muted">Client: Lyft</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="project.html" />
									<div class="link-mask-text">
										<a class="btn btn-secondary" href="project.html">
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div> */}
						{/* <div class="isotope-item col-md-6 mb-5 webapp frontend">
							<div class="card project-card">
								<div class="row no-gutters">
									<div class="col-lg-4 card-img-holder">
										<img src="assets/images/project/project-8.jpg" class="card-img" alt="image" />
									</div>
									<div class="col-lg-8">
										<div class="card-body">
											<h5 class="card-title">
												<a href="project.html" class="theme-link">
													Project Heading
												</a>
											</h5>
											<p class="card-text">
												Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cum sociis
												natoque penatibus et magnis dis parturient montes, nascetur ridiculus
												mus.{' '}
											</p>
											<p class="card-text">
												<small class="text-muted">Client: GitLab</small>
											</p>
										</div>
									</div>
								</div>
								<div class="link-mask">
									<a class="link-mask-link" href="project.html" />
									<div class="link-mask-text">
										<a class="btn btn-secondary" href="project.html">
											<i class="fas fa-eye mr-2" />View Source
										</a>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</section>

			<footer class="footer text-center py-4">
				<small class="copyright">
					Bryan Herrera &copy; <span>Mechanical Engineer</span>
				</small>
			</footer>
		</div>
	);
};

export default Portfolio;

import React from 'react';

const Contact = () => {
	return (
		<div>
			<div className="main-wrapper">
				<section className="cta-section theme-bg-light py-5">
					<div className="container text-center single-col-max-width">
						<h2 className="heading">Contact</h2>
						<div className="intro" />
					</div>
				</section>
				<section className="contact-section px-3 py-5 p-md-5">
					<div className="container">
						<form
							id="contact-form"
							className="contact-form col-lg-8 mx-lg-auto"
							method="post"
							action="https://formspree.io/f/mwkadroe"
						>
							{/* <h3 className="text-center mb-3">Get In Touch</h3> */}
							<div className="form-row">
								<div className="form-group col-md-6">
									<label className="sr-only" for="cname">
										Name
									</label>
									<input
										type="text"
										className="form-control"
										id="cname"
										name="name"
										placeholder="Name"
										minlength="2"
										required=""
										aria-required="true"
									/>
								</div>
								<div className="form-group col-md-6">
									<label className="sr-only" for="cemail">
										Email
									</label>
									<input
										type="email"
										className="form-control"
										id="cemail"
										name="email"
										placeholder="Email"
										required=""
										aria-required="true"
									/>
								</div>
								{/* <div className="form-group col-12">
									<select id="services" className="custom-select" name="services">
										<option selected>Select a service package you're interested in...</option>
										<option value="basic">Basic</option>
										<option value="standard">Standard</option>
										<option value="premium">Premium</option>
										<option value="not sure">Not sure</option>
									</select>
									<small className="form-text text-muted pt-1">
										<i className="fas fa-info-circle mr-2 text-primary" />Want to know what's
										included in each package? Check the{' '}
										<a href="services.html" target="_blank">
											Services &amp; Pricing
										</a>{' '}
										page.
									</small>
								</div> */}
								<div className="form-group col-12">
									<label className="sr-only" for="cmessage">
										Your message
									</label>
									<textarea
										className="form-control"
										id="cmessage"
										name="message"
										placeholder="Enter your message"
										rows="10"
										required=""
										aria-required="true"
									/>
								</div>
								<div className="form-group col-12">
									<button type="submit" className="btn btn-block btn-primary py-2">
										Send Now
									</button>
								</div>
							</div>
						</form>
					</div>
				</section>

				<footer className="footer text-center py-4">
					<small className="copyright">
						Bryan Herrera &copy; <span>Mechanical Engineer</span>
					</small>
				</footer>
			</div>
		</div>
	);
};

export default Contact;
